<script setup lang="ts">
import emblaCarouselVue from "embla-carousel-vue";
import { onMounted, ref } from "vue";
import { loadI18n, getCurrentLocale } from "@/i18n/i18n.client";
import {
	DialogContent,
	DialogOverlay,
	DialogPortal,
	DialogRoot,
} from "radix-vue";
import GalleryOverlayContent from "./GalleryOverlayContent.vue";

const { t } = await loadI18n(getCurrentLocale(), ["landing"]);

const [emblaNode, emblaApi] = emblaCarouselVue();

const selectedIndex = ref(0);
const scrollSnaps = ref<number[]>([]);

const canScrollNext = ref(false);
const canScrollPrev = ref(false);

const onSelect = () => {
	if (!emblaApi.value) return;
	scrollSnaps.value = emblaApi.value.scrollSnapList();
	selectedIndex.value = emblaApi.value.selectedScrollSnap();
	canScrollNext.value = emblaApi.value.canScrollNext() || false;
	canScrollPrev.value = emblaApi.value.canScrollPrev() || false;
};

onMounted(() => {
	if (emblaApi.value) {
		onSelect();
		emblaApi.value.on("reInit", onSelect);
		emblaApi.value.on("select", onSelect);
	}
});

const isGalleryOpen = ref(false);
</script>

<template>
	<DialogRoot v-model:open="isGalleryOpen">
		<DialogPortal>
			<DialogOverlay
				class="fixed inset-0 z-20 bg-stone-600/50 backdrop-blur-sm will-change-[opacity] data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
			/>
			<DialogContent
				class="fixed z-20 flex h-full w-screen flex-col rounded-xl px-5 pb-10 pt-12 outline-none will-change-[transform,opacity] data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 md:px-8"
				@click="isGalleryOpen = false"
			>
				<GalleryOverlayContent
					:model-value="selectedIndex"
					@update:model-value="
						(index) => {
							emblaApi?.scrollTo(index, true);
							selectedIndex = index;
						}
					"
				/>
			</DialogContent>
		</DialogPortal>
	</DialogRoot>
	<div class="flex w-full flex-col items-center lg:max-w-2xl">
		<div
			class="relative w-full overflow-hidden rounded-md bg-stone-800 shadow-xl ring-1 ring-white/10 sm:rounded-lg"
		>
			<div ref="emblaNode">
				<ul
					class="flex touch-pan-y touch-pinch-zoom [backface-visibility:hidden]"
				>
					<li class="min-w-0 shrink-0 grow-0 basis-full">
						<button type="button" @click="isGalleryOpen = true">
							<img
								src="/img/showcase/showcase_client_2.webp"
								fetchpriority="auto"
								width="1280"
								height="720"
								class="select-none"
								:alt="t('landing:showcase.slider.image_alt')"
							/>
						</button>
					</li>
					<li
						class="min-w-0 shrink-0 grow-0 basis-full"
						@click="isGalleryOpen = true"
					>
						<button type="button" @click="isGalleryOpen = true">
							<img
								src="/img/showcase/showcase_client_1.webp"
								loading="lazy"
								fetchpriority="low"
								decoding="async"
								width="1280"
								height="720"
								class="select-none"
								:alt="t('landing:showcase.slider.image_alt')"
							/>
						</button>
					</li>
					<li class="min-w-0 shrink-0 grow-0 basis-full">
						<button type="button" @click="isGalleryOpen = true">
							<img
								src="/img/showcase/showcase_client_3.webp"
								loading="lazy"
								fetchpriority="low"
								decoding="async"
								width="1280"
								height="720"
								class="select-none"
								:alt="t('landing:showcase.slider.image_alt')"
							/>
						</button>
					</li>
				</ul>
			</div>
		</div>
		<div class="mt-1 flex h-[21px] w-full max-w-sm gap-2.5">
			<button
				class="group flex-1 py-2 outline-none"
				v-for="(_, index) in scrollSnaps"
				type="button"
				:aria-label="
					t('landing:showcase.slider.go_to_slide', { slideNumber: index + 1 })
				"
				@click="emblaApi?.scrollTo(index)"
				:data-selected="index === selectedIndex ? true : undefined"
			>
				<div
					class="h-[5px] rounded-full bg-white/10 transition-colors group-hover:bg-white/[.15] group-data-[selected]:bg-white/[.35]"
				></div>
			</button>
		</div>
	</div>
</template>
